/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormGroup, Input, Label } from "reactstrap";

function SimpleInputField(props) {
  const {
    label,
    fieldType = "text",
    fieldName,
    isDisabled = false,
    isReadOnly = false,
    value,
    placeholder,
    onChangeAction,
    isMultiple = false,
    commonSelect,
    selectOption,
    minDate,
    maxDate,
    isFormGrop = true,
  } = props;

  const config =
    fieldType === "select"
      ? {
          type: "select",
          component: "select",
        }
      : fieldType === "number"
      ? {
          type: fieldType,
          min: "0",
        }
      : fieldType === "date"
      ? {
          type: fieldType,
          min: minDate,
          max: maxDate,
        }
      : fieldType === "time"
      ? {
          type: fieldType,
          min: minDate,
          max: maxDate,
        }
      : {
          type: fieldType,
        };

  return isFormGrop ? (
    <FormGroup>
      {label && <Label className="text-black fw-normal mb-1">{label}</Label>}
      {fieldType === "select" ? (
        <Input
          {...config}
          multiple={isMultiple}
          name={fieldName}
          disabled={isDisabled}
          readOnly={isReadOnly}
          placeholder={placeholder}
          className={classNames("form-control text-black reactSelect")}
          value={value}
          onChange={(e) => onChangeAction(e.target.value)}
        >
          {commonSelect && <option value="">{commonSelect}</option>}
          {!_.isEmpty(selectOption) &&
            _.map(selectOption, (val) => (
              <option value={val.id} key={`fieldName-${val.id}`}>
                {val.title}
              </option>
            ))}
        </Input>
      ) : (
        <Input
          {...config}
          name={fieldName}
          disabled={isDisabled}
          readOnly={isReadOnly}
          className={classNames("form-control text-black fw-bold" )}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChangeAction(e.target.value)}
        />
      )}
    </FormGroup>
  ) : (
    <>
      {label && <Label className="text-black fw-normal mb-1">{label}</Label>}
      {fieldType === "select" ? (
        <Input
          {...config}
          multiple={isMultiple}
          name={fieldName}
          disabled={isDisabled}
          readOnly={isReadOnly}
          placeholder={placeholder}
          className={classNames("form-control text-black")}
          value={value}
          onChange={(e) => onChangeAction(e.target.value)}
        >
          {commonSelect && <option value="">{commonSelect}</option>}
          {!_.isEmpty(selectOption) &&
            _.map(selectOption, (val) => (
              <option value={val.id} key={`fieldName-${val.id}`}>
                {val.title}
              </option>
            ))}
        </Input>
      ) : (
        <Input
          {...config}
          name={fieldName}
          disabled={isDisabled}
          readOnly={isReadOnly}
          className={classNames("form-control text-black")}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChangeAction(e.target.value)}
        />
      )}
    </>
  );
}

SimpleInputField.propTypes = {
  onChangeAction: PropTypes.func,
  value: PropTypes.any,
  selectOption: PropTypes.any,
  label: PropTypes.string,
  fieldType: PropTypes.string,
  fieldName: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isMultiple: PropTypes.bool,
  commonSelect: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};

export default SimpleInputField;
