import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
// import { Encrypt, Decrypt } from '../../middleware/CryptoMiddleWare';
import {  EncryptPreLogin, DecryptPreLogin, } from '../../middleware/CryptoMiddleWare';
import {consoleEnableKey} from '../../components/common/constant'

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export const fetchWithEncryption = async ({ url, method, body, token, consoleEnable}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    PT: process.env.REACT_APP_API_KEY,
    Authorization: `Bearer ${token}`,
    // 'X-XSRF-TOKEN':cfsf.csrfToken,
  };
  // console.log('REQUEST=>', `${url}`, body)
  // console.log('TOKEN=>', token)
  if (consoleEnable) {
    console.log('REQUEST=>', `${url}`, body)
    console.log('TOKEN=>', token)
  }
  const response = await fetch(url, {
    method,
    headers,
    body:  body ? await EncryptPreLogin(JSON.stringify(body)) : undefined,
  });
  const res = await response.text();
  // const abc ='sxNgybFA+2lLBOlL709gbmGkgk07+q8zAXuK3PKpqfEc/5i5gcAfetb6ntCOdhWSAHTYh6ATyiYyqw0yqNGfmDWpqDGNdkN5v4WBmQm4jdWKJwP8oSHVpCgOl3sOFLj109GI64FPcCMRv0wLifWt9s+VutacJQPvTnTI7wXWxvCgWjmqjWzkyA/Hx5mQThMaDl/CSV6vuzpQAmF+ND4ealjEYaFpwLpTS2mUWKiuSsYS6G6B7dspQSvB87ykzmxnEanvhTGfzDS3CLmQN9Q4aA/LtmmuESKOMfhYqoqHcRU8aA=='
  // const xyz = await DecryptPreLogin(abc);
  // console.log(xyz);
  const data = await DecryptPreLogin(res);
  const encodedString = data;
  // console.log('RESPONSE =>', `${url}`, JSON.parse(htmlDecode(encodedString)))
  if (consoleEnable) {
    console.log('RESPONSE =>', `${url}`, JSON.parse(htmlDecode(encodedString)))
  }
  // const UserData = JSON.parse(htmlDecode(encodedString));
  // console.log(UserData)
  // const data1 = JSON.parse(Decrypt(data));
  // console.log('Response =>', data1);
  return JSON.parse(htmlDecode(encodedString));
};

export const createEncryptedAsyncThunk = (
  typePrefix,
  urlOrUrlCreator, // Can be a string or a function
  { method = 'POST', transUrl, prepareBody = (params) => params } = {},
  baseUrl = transUrl || process.env.REACT_APP_API_ENDPOINT,
) =>
  createAsyncThunk(typePrefix, async (params, { rejectWithValue, getState }) => {
    try {
      const {managementSlice} = getState()
      let consoleEnable = false
      if (!_.isEmpty(managementSlice) && !_.isEmpty(managementSlice.consoleEnable)) {
        if (managementSlice.consoleEnable.master_key === consoleEnableKey) {
          consoleEnable = managementSlice.consoleEnable.status
        }
      }
      
      // Determine the URL based on whether urlOrUrlCreator is a string or a function
      const fullUrl =
        typeof urlOrUrlCreator === 'function'
          ? `${baseUrl}${urlOrUrlCreator(params)}` // Call the function with params to get the URL
          : `${baseUrl}${urlOrUrlCreator}`; // Use the URL as is

      const body = method !== 'GET' ? prepareBody(params) : null;
      const response = await fetchWithEncryption({
        url: fullUrl,
        method,
        body,
        token: params.Usertoken,
        consoleEnable
      });
      if (consoleEnable) {
        console.log('RESPONSE =>', `${fullUrl}`, response)
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  });
