/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { fetchWhiteLabelDetailsByDomain } from "../slices/admin/AdminService/whiteLabelSlice";
// import axios from "axios";
import _ from "lodash";
import {
  defaultButtonColor,
  defaultDashboardButtonColor,
  defaultDashboardMenuColor,
  defaultMenuColor,
  defaultSiteAddress,
  defaultSiteColor,
  defaultSiteContact,
  defaultSiteLogo,
  defaultSiteMail,
  defaultSiteName,
  templateDefault,
} from "../components/common/constant";

import defaultDashboardLogo from "../assets/images/logos/logo.png"

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  // const dispatch = useDispatch();
  const domainData = useSelector((state) => state.WhiteLabelSlice.domainData);
  // const domain = window.location ? window.location.hostname : "";

  const [logo, setLogo] = useState(defaultSiteLogo);
  const [dashboardLogo, setDashboardLogo] = useState(defaultDashboardLogo);
  const [siteName, setSiteName] = useState(defaultSiteName);
  const [siteAddress, setSiteAddress] = useState(defaultSiteAddress);
  const [siteContact, setSiteContact] = useState(defaultSiteContact);
  const [siteMail, setSiteMail] = useState(defaultSiteMail);
  const [templateName, setTemplateName] = useState(defaultButtonColor);
  const [siteColor, setSiteColor] = useState(defaultSiteColor);
  const [menuColor, setMenuColor] = useState(defaultMenuColor);
  const [buttonColor, setButtonColor] = useState(defaultButtonColor);
  const [dashboardMenuColor, setDashboardMenuColor] = useState(
    defaultDashboardMenuColor
  );
  const [dashboardButtonColor, setDashboardButtonColor] = useState(
    defaultDashboardButtonColor
  );

  // const getWhiteLabelList = (ip) => {
  //   dispatch(
  //     fetchWhiteLabelDetailsByDomain({ domainName: domain, ipAddress: ip })
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       console.log("res-white--", res);
  //     })
  //     .catch((err) => {
  //       console.log("err-white--", err);
  //     });
  // };

  // const getIPAddress = () => {
  //   // Fetch IP address of the domain using ipify API
  //   axios
  //     .get("https://api.ipify.org?format=json")
  //     .then((response) => {
  //       getWhiteLabelList(response.data.ip);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error fetching the IP address:", error);
  //     });
  // };

  // useEffect(() => {
  //   if (_.isEmpty(domainData)) {
  //     getIPAddress();
  //   }
  // }, [domain, domainData]);

  useEffect(() => {
    if (!_.isEmpty(domainData)) {
      const getLogo = domainData.logo
        ? _.join(["data:image/png;base64", domainData.logo], ",")
        : defaultSiteLogo;
      setLogo(getLogo);

      const getDLogo = domainData.logo
        ? _.join(["data:image/png;base64", domainData.logo], ",")
        : defaultDashboardLogo;
      setDashboardLogo(getDLogo);

      const getSiteName = domainData.name ? domainData.name : defaultSiteName;
      setSiteName(getSiteName);

      const getSiteAddress = domainData.address
        ? domainData.address
        : defaultSiteAddress;
      setSiteAddress(getSiteAddress);
      
      const contact_details = domainData.contact_details ? JSON.parse(domainData.contact_details) : null;
      if (!_.isEmpty(contact_details)) {
        const getSiteContact = contact_details.contact_no
          ? _.split(_.trim(contact_details.contact_no), ',')
          : defaultSiteContact;
        setSiteContact(getSiteContact);
  
        const getSiteMail = contact_details.contact_mail ? contact_details.contact_mail : defaultSiteMail;
        setSiteMail(getSiteMail);
      }


      setTemplateName(domainData.template_name);

      const getSiteColor =
        domainData.site_color && domainData.template_name !== templateDefault
          ? _.join(["#", domainData.site_color], "")
          : defaultSiteColor;
      setSiteColor(getSiteColor);

      const getMenuColor =
        domainData.menu_color && domainData.template_name !== templateDefault
          ? _.join(["#", domainData.menu_color], "")
          : defaultMenuColor;
      setMenuColor(getMenuColor);

      const getButtonColor =
        domainData.button_color && domainData.template_name !== templateDefault
          ? _.join(["#", domainData.button_color], "")
          : defaultButtonColor;
      setButtonColor(getButtonColor);

      const getDashboardMenuColor =
        domainData.dashboard_menu_color &&
        domainData.template_name !== templateDefault
          ? _.join(["#", domainData.dashboard_menu_color], "")
          : defaultDashboardMenuColor;
      setDashboardMenuColor(getDashboardMenuColor);

      const getDashboardButtonColor =
        domainData.dashboard_button_color &&
        domainData.template_name !== templateDefault
          ? _.join(["#", domainData.dashboard_button_color], "")
          : defaultDashboardButtonColor;
      setDashboardButtonColor(getDashboardButtonColor);
    }
  }, [domainData]);

  return (
    <ThemeContext.Provider
      value={{
        logo,
        dashboardLogo,
        siteName,
        siteAddress,
        siteContact,
        siteMail,
        templateName,
        siteColor,
        menuColor,
        buttonColor,
        dashboardMenuColor,
        dashboardButtonColor,
        domainData,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.prototype = {
  children: PropTypes.node,
};

const useTheme = () => useContext(ThemeContext);
export { useTheme, ThemeProvider };
