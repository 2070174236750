/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { fetchWhiteLabelDetailsByDomain } from "../slices/admin/AdminService/whiteLabelSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// import axios from "axios";

function useDynamicRoutes() {
  const dispatch = useDispatch();
  const domainData = useSelector((state) => state.WhiteLabelSlice.domainData);

  const domain = window.location ? window.location.hostname : "";

  const defaultDomain = domain === 'localhost' ? 'sspaytravel.com' : domain

  const getWhiteLabelList = (ip) => {
    dispatch(
      fetchWhiteLabelDetailsByDomain({ domainName: defaultDomain, ipAddress: ip })
    )
      .unwrap()
      .then((res) => {
        console.log("res-white--", );
      })
      .catch((err) => {
        console.log("err-white--", err);
      });
  };

  const fetchIpAddress = async () => {
    try {
      // Prepare the form data
      const formData = new FormData();
      formData.append("hostname", defaultDomain);
      formData.append("fromTab", "false");

      // Send POST request to the IP lookup endpoint
      const response = await fetch("https://www.site24x7.com/tools/find-ip", {
        method: "POST",
        headers: {
          Cookie:
            "_zcsr_tmp=e786c41f-4014-47c3-a1fd-44bb89b6cbe2; s247cname=e786c41f-4014-47c3-a1fd-44bb89b6cbe2; zalb_70fdde380e=2aec156791189a0253ad15dbba9e8d7a",
        },
        body: formData,
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();

      // Extract IP address from the response
      const ipString = data.ipList[0];
      const extractedIp = ipString.split("/")[1];
      getWhiteLabelList(extractedIp);
    } catch (err) {
      getWhiteLabelList('')
    }
  };

  // const getIPAddress = () => {
  //   // Fetch IP address of the domain using ipify API
  //   axios
  //     .get("https://api.ipify.org?format=json")
  //     .then((response) => {
  //       getWhiteLabelList(response.data.ip);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error fetching the IP address:", error);
  //     });
  // };

  useEffect(() => {
    if (_.isEmpty(domainData)) {
      fetchIpAddress();
    }
  }, [domain, domainData]);

  return domainData;
}

export default useDynamicRoutes;
