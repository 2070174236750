// import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";
import _ from "lodash";
import { DecryptPreLogin, EncryptPreLogin } from "../../../middleware/CryptoMiddleWare";
import { htmlDecode } from "../../authSlice/userSilce";

export const fetchWhiteLabelDetails = createEncryptedAsyncThunk(
  "whiteLabelSlice/fetchWhiteLabelDetails",
  "Whitelabel/GetWhiteLabelDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      wt_userId: params.wtUserId || 0,
      wt_id: params.wtId || 0,
    }),
  }
);

export const CreateWhitelabelDetails = createEncryptedAsyncThunk(
  "whiteLabelSlice/CreateWhitelabelDetails",
  "Whitelabel/AddWhitelabelDetails",
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      whitelabel_userid: params.whiteLabelUserId || 0,
      admin_userid: params.admin_userid || 0,
      name: params.name || "",
      domain_name: params.domain_name || "",
      ipaddress: params.ipaddress || "",
      logo: params.logo || "",
      template_name: params.template_name || "",
      site_color: params.site_color || "",
      menu_color: params.menu_color || "",
      button_color: params.button_color || "",
      dashboard_menu_color: params.dashboard_menu_color || "",
      dashboard_button_color: params.dashboard_button_color || "",
      address: params.address || "",
      contact_details: params.contact_details || "",
      status: params.status || 1,
      statusinfo: params.statusinfo || "",
      remark: params.remark || ""
    }),
  }
);

export const UpdateWhiteLabelDetails = createEncryptedAsyncThunk(
  "whiteLabelSlice/UpdateWhiteLabelDetails",
  "Whitelabel/UpdateWhiteLabelDetails",
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      wtL_id: params.wtL_id || 0,
      name: params.name || "",
      domain_name: params.domain_name || "",
      ipaddress: params.ipaddress || "",
      logo: params.logo || "",
      template_name: params.template_name || "",
      site_color: params.site_color || "",
      menu_color: params.menu_color || "",
      button_color: params.button_color || "",
      dashboard_menu_color: params.dashboard_menu_color || "",
      dashboard_button_color: params.dashboard_button_color || "",
      address: params.address || "",
      contactDetails: params.contact_details || "",
      status: params.status || 0,
      statusinfo: params.statusinfo || "",
      remark: params.remark || ""
    }),
  }
);

// export const fetchWhiteLabelDetailsByDomain = createEncryptedAsyncThunk(
//   "whiteLabelSlice/fetchWhiteLabelDetailsByDomain",
//   "Whitelabel/GetWhiteLabelDetailsByDomain",
//   {
//     prepareBody: (params) => ({
//       domainName: params.domainName || "",
//       ipaddress: params.ipAddress || "",
//     }),
//   }
// );

export const fetchWhiteLabelDetailsByDomain = createAsyncThunk(
  "whiteLabelSlice/fetchWhiteLabelDetailsByDomain",
  async (params) => {
    const rowData = {
      domainName: params.domainName || "",
      ipaddress: params.ipAddress || "",
    };

    const url = `${process.env.REACT_APP_API_ENDPOINT}Whitelabel/GetWhiteLabelDetailsByDomain`
// console.log(url, rowData)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        PT: process.env.REACT_APP_API_KEY,
      },
      body: await EncryptPreLogin(JSON.stringify(rowData)),
    });
    const data = await response.text();
    // const data = '/gSLRxS+UIaAsu62ymEthmNEgDwQUVzMlnW604/Vye20jE9HUM3xCIsauMrQhPOyJiLkuzpcxOH5d3cJWSsl+4p+TLplcfewoQR6vJQyBFU8lEekikdlv1L9EnKMZDOnQNHu7gmAxuJdPd9C9BLnnR+l4dxJsWWRm9N8mhEo2K3hnZiHNP2VMZNbe17682EDOiOy3qMw8aETMD/SfPEkSyYeIdyI9rvlAA4ybVvugUR6ybVImp6c3aV608EQP6AscQvOXNd4ojKO0mFMXcpVsZ4rZpii37CsUuSaHoSt40ZRYsoiCDMFu0cQcl7K06L1Kg=='
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  },
);

export const WhiteLabelSlice = createSlice({
  name: "WhiteLabelSlice",
  initialState: {
    domainData: null
  },
  reducers: {
    clearWhiteLabelData(state) {
      return { ...state, domainData: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhiteLabelDetailsByDomain.pending, (state) => {
        state.domainData = null;
      })
      .addCase(fetchWhiteLabelDetailsByDomain.fulfilled, (state, action) => {
        state.domainData = null;
        if (action.payload.data && !action.payload.data.message && !_.isEmpty(action.payload.data)) {
          state.domainData = action.payload.data;
        }
      })
      .addCase(fetchWhiteLabelDetailsByDomain.rejected, (state) => {
        state.domainData = null;
      })
    }
});
export const {clearWhiteLabelData} = WhiteLabelSlice.actions;
export default WhiteLabelSlice.reducer;