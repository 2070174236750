export const rowPerPage = 50
export const antTableScroll = { x: true }
export const addFormType = 'Add'
export const editFormType = 'Edit'

export const transactionTypeList = [
  { id: "All", title: "Select All" },
  { id: "0", title: "Pending" },
  { id: "1", title: "Success" },
  { id: "2", title: "Failed" },
  { id: "3", title: "Refund" },
]

export const getTodayDate = () => {
  // Create a new Date object for the current time
  // const now = new Date();
  // // Adjust the time for IST (+05:30)
  // const istOffset = 5.5 * 60; // IST offset in minutes
  // const localOffset = now.getTimezoneOffset(); // Local timezone offset in minutes
  // const offsetInMinutes = istOffset - localOffset;
  // now.setMinutes(now.getMinutes() + offsetInMinutes);
  // const date = now.toISOString().split("T")[0];

  const date = new Date().toISOString().split("T")[0];
  return date
}

export const noImage = "../assets/images/no-image.jpg";

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

async function handleAllImagesUpLoad(file) {
  const base64 = await convertToBase64(file);
  // console.log('base64---', base64)
  const base642 = base64.split(',');
  return base642[1];
}
export default handleAllImagesUpLoad

export const urlForInputFile = (file) => URL.createObjectURL(file)

export function splitImageBaseData(data) {
  const base642 = data.split(',');
  return base642[1];
}

export const templateDefault = 'DEFAULT'
export const templateOne = 'TEMPLATE1'
export const templateTwo = 'TEMPLATE2'
export const templateThree = 'TEMPLATE3'

export const defaultSiteLogo = "assets/images/logo.png"
export const defaultSiteName = 'Shree Shyam Pay Pvt Ltd'
export const defaultSiteAddress = '3772/19 REGHAR PURA KAROL BAGH NEW DELHI - 110005 Near vishnu mandir'
export const defaultSiteContact = '9205844811,9205844822,9205844833'
export const defaultSiteMail = 'info@sspay.com'
export const defaultSiteColor = '#fff'
export const defaultMenuColor = '#0a165e'
export const defaultButtonColor = '#9e2a96'
export const defaultDashboardMenuColor = '#000'
export const defaultDashboardButtonColor = '#6f0d5b'

export const adminRole = 1
export const consoleEnableKey = 'CONSOLE_ENABLE'
export const appConfigKey = [
  // 'LOGIN_ENABLE',
  consoleEnableKey,
  'FUNDTRANSFER_TIME',
  'LOGIN_TIME',
  'TWO_FACTOR_AUTHENTICATE',
  'SMS_ENABLE',
  'SMS_SEND_USER_DETAILS',
  'SMS_TRANSACTION_MESSAGE',
  'SMS_RECHARGE_MESSAGE',
  'SMS_MODE_ENABLE',
  'MOBILE_NO_LOGIN',
]


export const popButtonDefaultIcon = <i className="bi bi-question-circle-fill fs-4 me-2 text-warning" />
export const popButtonDefaultTitile = "Confirmation"
export const popButtonDefaultDesc = (val = '') => `Are you sure to ${val || 'perform this action'}.`
export const popButtonStatusIcon = <i className="bi bi-exclamation-circle-fill fs-4 me-2 text-warning" />
export const popButtonStatusTitile = "Change Status"
export const popButtonStatusDesc = (val = '') => `Are you sure to change ${val || 'this data'} status.`
export const popButtonDeleteIcon = <i className="bi bi-question-circle-fill fs-4 me-2 text-warning" />
export const popButtonDeleteTitile = "Delete data"
export const popButtonDeleteDesc = (val = '') => `Are you sure to delete ${val || 'this data'}.`


export const ellipsisLast = (data, limit = 1, fromWhere = 'last') => {
  if (data && data.length > limit) {
    if (fromWhere === 'last') {
      return `${data.substring(0, limit)}...`;
    }
    if (fromWhere === 'start') {
      return `...${data.substring(data.length - limit, limit)}`;
    }
  }
  return data;
};

export const ellipsisButtonTitle = 'View More'